import React, { useState, useEffect } from 'react';
import DeliveryMap from './DeliveryMap';
import Navbar from './Navbar'
import './tracking.css';


import { formatStatusFields, formatETA, formatCreatedAt, formatSnakeCase, MapLoading } from './utils';
import { DeliveryTrackingBox, DeliveryProof, DeliveryTimeline } from './DeliveryTrackingBoxes'

import { Wrapper } from '@googlemaps/react-wrapper';


function formatSnakeCases(snakeCaseString) {
  return snakeCaseString
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

const DeliveryTrackingPage = () => {
    const [jobId, setJobId] = useState(new URLSearchParams(window.location.search).get('jobId'));
    const [valid, setValid] = useState(false);
	const [orderId, setOrderId] = useState('');
 const [isOpen, setIsOpen] = useState(false);
 const [providerId, setProviderId] = useState('');

  // Function to open the modal
  const openModal = () => {
    setIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
  };
 const [isToggled, setIsToggled] = useState([false, false]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    // Function to check screen size
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    // Add event listener to track screen size changes
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggle = (index) => {
    setIsToggled((prev) =>
      prev.map((item, i) => (i === index ? !item : item))
    );
  };
    // Stores pickup and dropoff locations (set once)
    // Converting addresses costs money, so we only want it to happen once
    const [deliveryData, setDeliveryData] = useState({
        pickupAddress: '',
        dropoffAddress: '',
    });

    // Stores current delivery status (changes)
    const [deliveryStatus, setDeliveryStatus] = useState({
        driver: '',
        driverLocation: '',
        driverStatus: '',
        driverNumber: '',
        courierPickupPhoneNumber: '',
        courierVehicle: '',
        eta: '',
    });

	const [distance, setDistance] = useState(null);

    // Stores current delivery step
    const [deliveryStep, setDeliveryStep] = useState({
        assignedDriver: '12:00pm',
        pickupEnroute: '12:34pm',
        pickupComplete: '-',
        dropoffEnroute: '-',
        dropoffArrived: '-',
        dropoffCompleted: '-'
    });

    const getMainPhoneNumberAndExtension = (phoneNumber) => {
    	// Check if the phone number exists and is a string
	    if (typeof phoneNumber !== 'string' || !phoneNumber) {
	        return { mainNumber: '', extension: '' };
	    }

	    const match = phoneNumber.match(/^(\+\d+),(\d+)$/);
	    if (match) {
	        return { mainNumber: match[1], extension: match[2] };
	    }
	    return { mainNumber: phoneNumber, extension: '' };
	};

    /*const getMainPhoneNumberAndExtension = (phoneNumber) => {
    	const [mainNumber, extension] = phoneNumber.split(",");
    	return { mainNumber, extension: extension || '' };
	};*/
console.log('deliveryStatus :>> ', deliveryStatus);
	console.log("full:", deliveryStatus.courierPickupPhoneNumber);
    const { mainNumber, extension } = getMainPhoneNumberAndExtension(deliveryStatus.courierPickupPhoneNumber);

	/*const getVehicleString = (vehicle) => {
	    if (!vehicle || !vehicle.make || !vehicle.model || !vehicle.color) return '';
	    return `${vehicle.make} ${vehicle.model} - ${vehicle.color}`;
	};
*/

    const getVehicleString = (vehicle) => {
	    if (!vehicle) return '-';
	    
	    const { make, model, color } = vehicle;

	    if (make) {
	        return `${make} ${model || ''} - ${color || ''}`.trim();
	    } else {
	        return `${model || ''} - ${color || ''}`.trim();
	    }
	};

	const vehicleString = getVehicleString(deliveryStatus.courierVehicle);


	const calculateDistance = async (origin, destination) => {
		if (!origin || !destination) return;
		
		// Ensure Google Maps API is available
		if (!window.google || !window.google.maps) {
			console.error("Google Maps API is not loaded yet.");
			return;
		}
	
		const service = new window.google.maps.DistanceMatrixService();
		service.getDistanceMatrix(
			{
				origins: [origin],
				destinations: [destination],
				travelMode: window.google.maps.TravelMode.DRIVING,
				unitSystem: window.google.maps.UnitSystem.IMPERIAL, // Ensures miles output
			},
			(response, status) => {
				if (status === 'OK') {
					const distanceText = response.rows[0].elements[0].distance.text; 
					setDistance(distanceText);
				} else {
					console.error('Distance Matrix request failed due to:', status);
				}
			}
		);
	};

    useEffect(() => {
        if (deliveryData.pickupAddress && deliveryData.dropoffAddress) {
			console.log("pickupaddress", deliveryData.pickupAddress);
			console.log("dropoffaddress", deliveryData.dropoffAddress);
            calculateDistance(deliveryData.pickupAddress, deliveryData.dropoffAddress);
        }
    }, [deliveryData.pickupAddress, deliveryData.dropoffAddress]);
	

    const updateDeliveryStatus = async (first = false) => {
        let result;

        try {
            const response = await fetch(process.env.REACT_APP_DELIVERY_STATUS_ENDPOINT + jobId);
            setValid(response.status === 200);
            result = await response.json();
            console.log("result", result);
        } catch (err) {
            console.log(err);
            setValid(false);
        }

        if (first) {
            setDeliveryData({
                pickupAddress: result.pickupAddress,
                dropoffAddress: result.dropoffAddress,
            });
        }
        setOrderId(result.orderId);
        console.log("resprovider", result);
        setProviderId(result.providerId);

        result = formatStatusFields(result);

        setDeliveryStatus({
            driver: result.courierName,
            driverLocation: result.driverLocation,
            driverStatus: result.status,
            driverNumber: result.courierPhoneNumber,
            courierPickupPhoneNumber: result.courierPickupPhoneNumber,
            courierVehicle: result.courierVehicle,
            eta: result.dropoffEta,
            statusHistory: result.statusHistory,
            proofOfDelivery: result.proofOfDelivery,
        });
    }

    useEffect(() => {
        // Get initial delivery data
        updateDeliveryStatus(true);

        // Get additional update every 30 seconds
        const interval = setInterval(() => {
            updateDeliveryStatus();
        }, 3000);

        // This will be run when the component is destroyed
        return () => clearInterval(interval);
    }, []);

    const dropoffETA = providerId === 'roadie_partner' || providerId === 'roadie_rush_partner'
    ? 'Less than 3 hours'
    : formatETA(deliveryStatus.eta);

    if (!valid)
        return <p>Loading</p>

    return (
       <div id='deliverytracking-wrapper'>
            <Navbar />
			 <div className='deliverytracking-main'>
				<div className='deliverytracking-sec'>
					<div className='deliverytracking-left timeline-left'>
							{isMobile ? ( 
							<>
							<button onClick={() => toggle(0)} className="delivery-toogle" id={isToggled[0] ? 'removeminus' : 'removemplus'}> 
								<div className="delivery-timeline-top">
								<div className="delivery-timeline-status">
									<DeliveryTrackingBox
										elements={[
											{ label: '', value: formatSnakeCases(deliveryStatus.driverStatus) },
										]}
									/>
								</div>
								 <div className="delivery-timeline-order"><div>Order Number:</div>{orderId}</div>
								</div>
								<div className="delivery-timeline-arrow"> {isToggled[0] ? <span><svg width="22" height="68" viewBox="0 0 22 68" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 29L10.2929 38.2929C10.6834 38.6834 11.3166 38.6834 11.7071 38.2929L21 29" stroke="#686868" stroke-width="2" stroke-linecap="round"/>
								</svg></span> : <svg width="22" height="68" viewBox="0 0 22 68" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 29L10.2929 38.2929C10.6834 38.6834 11.3166 38.6834 11.7071 38.2929L21 29" stroke="#686868" stroke-width="2" stroke-linecap="round"/>
								</svg>
								}</div>
							</button>
							{isToggled[0] && (
							<div className='delivery-timeline-container'>
								<DeliveryTimeline
									steps={deliveryStatus.statusHistory}
								/>
							</div>
							)}</>
							) : (
							<>
							<div className="delivery-timeline-top">
							<div className="delivery-timeline-status">
								<DeliveryTrackingBox
									elements={[
										{ label: '', value: formatSnakeCases(deliveryStatus.driverStatus) },
									]}
								/>
							</div>
							 <div className="delivery-timeline-order"><div>Order Number:</div>{orderId}</div>
							</div>
							<div className='delivery-timeline-container'>
								
								<DeliveryTimeline
									steps={deliveryStatus.statusHistory}
								/>
							</div>
							</>
							)}
					</div>
					<div className='deliverytracking-right map-border'>
						<div id='deliverytracking-map-wrapper'>
							<div id='deliverytracking-map'>
							<Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} render={MapLoading} libraries={['places, directions', 'geometry']}>
								<DeliveryMap className='deliverytracking-border'
									pickupAddress={deliveryData.pickupAddress}
									dropoffAddress={deliveryData.dropoffAddress}
									driverLocation={deliveryStatus.driverLocation}
								/>
							</Wrapper>
							</div>
							<div className='deliverytracking-map-deatils'>
								{isMobile ? ( 
										<>
										<button onClick={() => toggle(1)} className="driver-toogle" id={isToggled[1] ? 'removeplus' : 'removeminus'}>  {isToggled[1] ? <span>-</span> : 'View Driver Details'}</button>
										{isToggled[1] && (
											<DeliveryTrackingBox
											elements={[
											{ label: 'Driver: ', value: deliveryStatus.driver || 'Not Yet Assigned' },
											{
	                                            label: 'Number: ',
	                                            value: deliveryStatus.courierPickupPhoneNumber
	                                                ? (
									                    extension ? (
									                        <>
									                            <a href={`tel:${mainNumber},${extension}`} style={{ color: '#fff', textDecoration: 'none' }}>{mainNumber}</a> 
												                <a href={`tel:${mainNumber},${extension}`} className="call-icon-toogle" style={{ color: '#fff', textDecoration: 'none' }}>
																	<svg width="18" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)"><path d="M5.182 6.987c.571 1.036 1.23 2.03 2.084 2.936.858.912 1.925 1.742 3.306 2.451.102.05.199.05.286.016.131-.05.266-.16.397-.292.102-.103.23-.267.362-.447.532-.702 1.189-1.573 2.117-1.137.02.01.036.021.057.03l3.096 1.789c.01.005.02.015.03.02.407.283.576.718.58 1.212 0 .502-.184 1.066-.454 1.543-.357.63-.882 1.047-1.488 1.322-.577.267-1.219.41-1.836.502-.968.143-1.876.052-2.804-.235-.908-.282-1.822-.748-2.821-1.368l-.074-.047c-.457-.288-.953-.595-1.438-.958-1.777-1.349-3.588-3.297-4.768-5.44C.825 7.083.286 5.141.58 3.29c.163-1.015.596-1.938 1.352-2.547C2.59.21 3.477-.082 4.625.021c.132.01.25.086.312.2l1.985 3.373c.29.378.327.754.167 1.13-.131.306-.397.59-.76.854a5.765 5.765 0 0 1-.367.283c-.444.324-.95.698-.776 1.137l-.004-.011Z" fill="#fff"/></g><defs><clipPath id="a"><path fill="#fff" transform="translate(.5)" d="M0 0h17v17H0z"/></clipPath></defs></svg>
																</a>
			                                                    <div className="driver-ext-number">ext. {extension}</div>
									                        </>
									                    ) : (
									                        <>
									                           <a href={`tel:${mainNumber}`}  style={{ color: '#fff', textDecoration: 'none' }}>{mainNumber}</a> 
												               <a href={`tel:${mainNumber}`}  className="call-icon-toogle" style={{ color: '#fff', textDecoration: 'none' }}>
																<svg width="18" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)"><path d="M5.182 6.987c.571 1.036 1.23 2.03 2.084 2.936.858.912 1.925 1.742 3.306 2.451.102.05.199.05.286.016.131-.05.266-.16.397-.292.102-.103.23-.267.362-.447.532-.702 1.189-1.573 2.117-1.137.02.01.036.021.057.03l3.096 1.789c.01.005.02.015.03.02.407.283.576.718.58 1.212 0 .502-.184 1.066-.454 1.543-.357.63-.882 1.047-1.488 1.322-.577.267-1.219.41-1.836.502-.968.143-1.876.052-2.804-.235-.908-.282-1.822-.748-2.821-1.368l-.074-.047c-.457-.288-.953-.595-1.438-.958-1.777-1.349-3.588-3.297-4.768-5.44C.825 7.083.286 5.141.58 3.29c.163-1.015.596-1.938 1.352-2.547C2.59.21 3.477-.082 4.625.021c.132.01.25.086.312.2l1.985 3.373c.29.378.327.754.167 1.13-.131.306-.397.59-.76.854a5.765 5.765 0 0 1-.367.283c-.444.324-.95.698-.776 1.137l-.004-.011Z" fill="#fff"/></g><defs><clipPath id="a"><path fill="#fff" transform="translate(.5)" d="M0 0h17v17H0z"/></clipPath></defs></svg>
															   </a>
									                        </>
									                    )
									                ) : 'Not Yet Assigned'
                                    		},
                                    		{ label: 'Vehicle: ', value: vehicleString || 'Not Yet Assigned' },
											{ label: 'Distance:', value: distance || '-' },
                                    		{ label: 'Drop-off ETA: ', value: dropoffETA },
											]}
											/>
										)}</>
										) : (
											<DeliveryTrackingBox
											elements={[
											{ label: 'Driver: ', value: deliveryStatus.driver || 'Not Yet Assigned' },
											{
	                                            label: 'Number: ',
	                                            value: deliveryStatus.courierPickupPhoneNumber
	                                                ? (
									                    extension ? (
									                        <>
									                            <a href={`tel:${mainNumber},${extension}`} style={{ color: '#fff', textDecoration: 'none' }}>{mainNumber}</a> 
												                <a href={`tel:${mainNumber},${extension}`} className="call-icon-toogle" style={{ color: '#fff', textDecoration: 'none' }}>
																	<svg width="18" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)"><path d="M5.182 6.987c.571 1.036 1.23 2.03 2.084 2.936.858.912 1.925 1.742 3.306 2.451.102.05.199.05.286.016.131-.05.266-.16.397-.292.102-.103.23-.267.362-.447.532-.702 1.189-1.573 2.117-1.137.02.01.036.021.057.03l3.096 1.789c.01.005.02.015.03.02.407.283.576.718.58 1.212 0 .502-.184 1.066-.454 1.543-.357.63-.882 1.047-1.488 1.322-.577.267-1.219.41-1.836.502-.968.143-1.876.052-2.804-.235-.908-.282-1.822-.748-2.821-1.368l-.074-.047c-.457-.288-.953-.595-1.438-.958-1.777-1.349-3.588-3.297-4.768-5.44C.825 7.083.286 5.141.58 3.29c.163-1.015.596-1.938 1.352-2.547C2.59.21 3.477-.082 4.625.021c.132.01.25.086.312.2l1.985 3.373c.29.378.327.754.167 1.13-.131.306-.397.59-.76.854a5.765 5.765 0 0 1-.367.283c-.444.324-.95.698-.776 1.137l-.004-.011Z" fill="#fff"/></g><defs><clipPath id="a"><path fill="#fff" transform="translate(.5)" d="M0 0h17v17H0z"/></clipPath></defs></svg>
																</a>
			                                                    <div className="driver-ext-number">ext. {extension}</div>
									                        </>
									                    ) : (
									                        <>
									                           <a href={`tel:${mainNumber}`}  style={{ color: '#fff', textDecoration: 'none' }}>{mainNumber}</a> 
												               <a href={`tel:${mainNumber}`}  className="call-icon-toogle" style={{ color: '#fff', textDecoration: 'none' }}>
																<svg width="18" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)"><path d="M5.182 6.987c.571 1.036 1.23 2.03 2.084 2.936.858.912 1.925 1.742 3.306 2.451.102.05.199.05.286.016.131-.05.266-.16.397-.292.102-.103.23-.267.362-.447.532-.702 1.189-1.573 2.117-1.137.02.01.036.021.057.03l3.096 1.789c.01.005.02.015.03.02.407.283.576.718.58 1.212 0 .502-.184 1.066-.454 1.543-.357.63-.882 1.047-1.488 1.322-.577.267-1.219.41-1.836.502-.968.143-1.876.052-2.804-.235-.908-.282-1.822-.748-2.821-1.368l-.074-.047c-.457-.288-.953-.595-1.438-.958-1.777-1.349-3.588-3.297-4.768-5.44C.825 7.083.286 5.141.58 3.29c.163-1.015.596-1.938 1.352-2.547C2.59.21 3.477-.082 4.625.021c.132.01.25.086.312.2l1.985 3.373c.29.378.327.754.167 1.13-.131.306-.397.59-.76.854a5.765 5.765 0 0 1-.367.283c-.444.324-.95.698-.776 1.137l-.004-.011Z" fill="#fff"/></g><defs><clipPath id="a"><path fill="#fff" transform="translate(.5)" d="M0 0h17v17H0z"/></clipPath></defs></svg>
															   </a>
									                        </>
									                    )
									                ) : 'Not Yet Assigned'
                                    		},
                                    		{ label: 'Vehicle: ', value: vehicleString || 'Not Yet Assigned' },
											{ label: 'Distance:', value: distance || 'Calculating...' },
											{ label: 'Drop-off ETA: ', value: dropoffETA },
											]}
											/>
										)}
							</div>
						</div>
					</div>
				</div>
				<div className='deliverytracking-sec'>
					<div className='deliverytracking-left'>
						<div className='Delivery-Pickup'>
							
							<DeliveryTrackingBox
							elements={[
							{ label: 'Pickup: ', value: deliveryData.pickupAddress },
							{ label: 'Dropoff: ', value: deliveryData.dropoffAddress },
							]}
							/>
						</div>
					</div>
                    {deliveryStatus.proofOfDelivery && (
                        <div className='deliverytracking-right proofOfdelivery-sec'>
                            <div id="deliveryProof-info">
							 <div>
								 <button onClick={openModal} style={{ cursor: 'pointer', maxWidth: '230px' }} > <DeliveryProof proof={deliveryStatus.proofOfDelivery} /></button>
									{isOpen && (
										<div className="modal-overlay" onClick={closeModal}>
									  <div className="modal-content" onClick={(e) => e.stopPropagation()}>
										<span className="close-button" onClick={closeModal}>
										  &times;
										</span>
										<DeliveryProof proof={deliveryStatus.proofOfDelivery} />
									  </div>
									</div>
								  )}
								</div>
                            
                            </div>
							
                        </div>
                    )}
			   </div>
          </div>  
           
            {/* <div id="deliverytracking-page-right-side"> */}
            {/* <DeliveryTrackingStep title="Assigned Driver" time={deliveryStep.assignedDriver} />
                    <DeliveryTrackingStep title="Pickup Enroute" time={deliveryStep.pickupEnroute} />
                    <DeliveryTrackingStep title="Pickup Complete" time={deliveryStep.pickupComplete} />
                    <DeliveryTrackingStep title="Dropoff Enroute" time={deliveryStep.dropoffEnroute} />
                    <DeliveryTrackingStep title="Dropoff Arrived" time={deliveryStep.dropoffArrived} />
                    <DeliveryTrackingStep title="Dropoff Complete" time={deliveryStep.dropoffCompleted} /> */}
            {/* </div> */}
        </div>
    );
}

export default DeliveryTrackingPage